/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from '../models/ApiResponse';
import BaseService from './BaseService';
import {
  PublicFormAuthorized,
  PublicFormTitle,
  ClientPublicForm,
  ClientPublicFormFilter,
  ClientPublicFormRequest,
  PublicFormConfiguration,
} from '../models/ClientPublicForm';
import { AnswerResponse, PublicAdHocFormAnswerRequest } from '../models/Form';
import { FormBuilderPlaceholder } from '../components/form-builder/FormBuilderTypes';

export default class ClientPublicFormService extends BaseService {
  public static authorize(
    publicFormId: string,
    friendlyCaptchaSolution: string,
    friendlyCaptchaSiteKey: string,
  ): Promise<ApiResponse<PublicFormAuthorized>> {
    return this.get(`/v1/public-forms/${publicFormId}/authorize`, { params: { friendlyCaptchaSolution, friendlyCaptchaSiteKey } });
  }

  public static getTitle(publicFormId: string): Promise<ApiResponse<PublicFormTitle>> {
    return this.get(`/v1/public-forms/${publicFormId}/info`);
  }

  public static getPublicForms(clientId: string, filter: ClientPublicFormFilter = {}): Promise<ApiResponse<ClientPublicForm[]>> {
    return this.get('/v1/public-forms', { params: { clientId: clientId, ...filter } });
  }

  public static createPublicForm(publicFormRequest: ClientPublicFormRequest): Promise<ApiResponse<ClientPublicForm>> {
    return this.post('/v1/public-forms', publicFormRequest);
  }

  public static updatePublicForm(id: string, publicFormConfig: PublicFormConfiguration): Promise<ApiResponse<ClientPublicForm>> {
    return this.put(`/v1/public-forms/${id}`, publicFormConfig);
  }

  public static answer(
    id: string,
    actionId: string,
    data: any,
    templateFormVersion?: number,
    addedPlaceholders?: FormBuilderPlaceholder[],
    removedPlaceholders?: string[],
  ): Promise<ApiResponse<AnswerResponse>> {
    return this.post(`/v1/public-forms/${id}/answer`, {
      actionId,
      data,
      templateFormVersion: templateFormVersion || null,
      addedPlaceholders,
      removedPlaceholders,
    });
  }

  public static submit(id: string): Promise<ApiResponse<string>> {
    return this.post(`/v1/public-forms/${id}/submit`);
  }

  public static createAdHocAnswer(id: string, payload: PublicAdHocFormAnswerRequest): Promise<ApiResponse<AnswerResponse>> {
    return this.post(`/v1/public-forms/${id}/answer/ad-hoc`, payload);
  }

  public static deleteAdHocAnswer(id: string, actionId: string, fieldId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/public-forms/${id}/answer/ad-hoc`, { params: { actionId, fieldId } });
  }

  public static sortAdHocAnswers(id: string, actionId: string, fieldIds: string[]): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/public-forms/${id}/answer/ad-hoc/sort`, { actionId, fieldIds });
  }
}
